export enum Teams {
    Astralis = 'astralis',
    BIG = 'big',
    CopenhagenFlames = 'copenhagen-flames',
    Ence = 'ence',
    Entropiq = 'entropiq',
    FaZe = 'faze-clan',
    Godsent = 'godsent',
    Heroic = 'heroic',
    Mousesports = 'mousesports',
    MovistarRiders = 'movistar-riders',
    paiN = 'pain-gaming',
    Renegades = 'renegades',
    Sharks = 'sharks',
    Spirit = 'team-spirit',
    Tyloo = 'tyloo',
    VirtusPro = 'virtus-pro',
    
    NatusVincere = 'navi',
    NinjasInPyjamas = 'nip',
    Furia = 'furia',
    Vitality = 'vitality',
    Liquid = 'liquid',
    Gambit = 'gambit',
    G2 = 'g2',
    EvilGeniuses = 'eg',
}