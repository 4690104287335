




import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Teams } from './Teams';

@Component({})
export default class Team extends Vue {
    @Prop({ required: true, type: String as PropType<Teams> })
    private team!: Teams;
}
