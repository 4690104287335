






import { Component, Prop, Vue } from 'vue-property-decorator';
import ChallengerStage from '@/components/ChallengerStage.vue';

@Component({
    components: {
        ChallengerStage,
    },
})
export default class App extends Vue {
}
