import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import ChallengerStage from '@/components/ChallengerStage.vue';
import LegendsStage from '@/components/LegendsStage.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/challenger-stage',
        component: ChallengerStage,
    },
    {
        path: '/legends-stage',
        component: LegendsStage,
    },
    {
        path: '*',
        redirect: '/legends-stage',
    },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
