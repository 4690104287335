






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Teams } from './Teams';
import Team from './Team.vue';

interface Match {
    teams: [TeamData, TeamData];
    winner: TeamData;
}

interface TeamData {
    name: Teams;
    rmrSeed: number;
    wins: number;
    losses: number;
    opponents: TeamData[];
}

type LegendsTeam =
    Teams.NinjasInPyjamas
    | Teams.Furia
    | Teams.NatusVincere
    | Teams.Vitality
    | Teams.Liquid
    | Teams.Gambit
    | Teams.G2
    | Teams.EvilGeniuses

    | Teams.FaZe
    | Teams.CopenhagenFlames
    | Teams.Entropiq
    | Teams.VirtusPro
    | Teams.Ence
    | Teams.Heroic
    | Teams.Astralis
    | Teams.Mousesports
    ;

type SwissMatches = Record<number, Record<string, Match[]>>;

interface TeamsByScore {
    score: string;
    teams: TeamData[];
}

@Component({
    components: {
        Team,
    },
})
export default class LegendsStage extends Vue {
    private readonly matches: SwissMatches = {};

    private get teamsByScore(): TeamsByScore[] {
        const scores: TeamsByScore[] = [];
        const teams = Object.values(this.teamData)
            .sort((a, b) => this.compareTeams(a, b));

        teams.forEach(team => {
            const key = `${team.wins}-${team.losses}`;
            let score = scores.find(s => s.score == key);
            if (score == null) {
                score = {
                    score: key,
                    teams: [],
                };
                scores.push(score);
            }

            score.teams.push(team);
        });

        return scores;
    }

    private readonly teamData: Record<LegendsTeam, TeamData> = {
        [Teams.NinjasInPyjamas]: {
            name: Teams.NinjasInPyjamas,
            rmrSeed: 1,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.Furia]: {
            name: Teams.Furia,
            rmrSeed: 2,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.NatusVincere]: {
            name: Teams.NatusVincere,
            rmrSeed: 3,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.Vitality]: {
            name: Teams.Vitality,
            rmrSeed: 4,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.Liquid]: {
            name: Teams.Liquid,
            rmrSeed: 5,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.Gambit]: {
            name: Teams.Gambit,
            rmrSeed: 6,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.G2]: {
            name: Teams.G2,
            rmrSeed: 7,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.EvilGeniuses]: {
            name: Teams.EvilGeniuses,
            rmrSeed: 8,
            wins: 0,
            losses: 0,
            opponents: [],
        },


        [Teams.FaZe]: {
            name: Teams.FaZe,
            rmrSeed: 9,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.CopenhagenFlames]: {
            name: Teams.CopenhagenFlames,
            rmrSeed: 10,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.Ence]: {
            name: Teams.Ence,
            rmrSeed: 11,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.Entropiq]: {
            name: Teams.Entropiq,
            rmrSeed: 12,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.VirtusPro]: {
            name: Teams.VirtusPro,
            rmrSeed: 13,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.Heroic]: {
            name: Teams.Heroic,
            rmrSeed: 14,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.Astralis]: {
            name: Teams.Astralis,
            rmrSeed: 15,
            wins: 0,
            losses: 0,
            opponents: [],
        },

        [Teams.Mousesports]: {
            name: Teams.Mousesports,
            rmrSeed: 16,
            wins: 0,
            losses: 0,
            opponents: [],
        },

    };

    private compareTeams(a: TeamData, b: TeamData) {
        if (a.wins != b.wins) {
            return b.wins - a.wins;
        }
        if (a.losses != b.losses) {
            return a.losses - b.losses;
        }

        const aBuchholz = this.getBuchholzScore(a);
        const bBuchholz = this.getBuchholzScore(b);
        if (aBuchholz != bBuchholz) {
            return bBuchholz - aBuchholz;
        }

        return a.rmrSeed - b.rmrSeed;
    }

    private getBuchholzScore(team: TeamData) {
        const wins = team.opponents.reduce((total, cur) => total + cur.wins, 0);
        const losses = team.opponents.reduce((total, cur) => total + cur.losses, 0);

        return wins - losses;
    }

    private setWinner(match: Match, team: TeamData) {
        match.winner = team;

        this.updateMatches();
    }

    created() {
        this.updateMatches();
    }

    private getMatchups(): Match[] {
        const groups = Object.values(this.teamData)
            .filter(team => team.wins < 3 && team.losses < 3)
            .sort((a, b) => this.compareTeams(a, b))
            .reduce((acc, cur) => {
                const score = `${cur.wins}-${cur.losses}`;
                const teams = (acc[score] ??= []);
                teams.push(cur);
                return acc;
            }, {} as Record<string, TeamData[]>);

        return Object.keys(groups).flatMap(key => {
            const teams = groups[key];
            const matches = this.findMatchups([...teams]);
            if (matches == null) {
                throw new Error('no matches found');
            }

            return matches;
        });
    }

    private findMatchups(teams: TeamData[]): Match[] | null {
        if (teams.length == 0) {
            return [];
        }

        const a = teams.shift()!;
        const bChoices = teams.filter(t => !a.opponents.includes(t)).reverse();
        if (bChoices.length == 0) {
            return null;
        }

        for (const b of bChoices) {
            const match: Match = {
                teams: [a, b],
                winner: a,
            };

            const found = this.findMatchups(teams.filter(t => t != a && t != b));
            if (found != null) {
                return [
                    match,
                    ...found,
                ];
            }
        }

        return null;
    }

    private updateMatches() {

        Object.values(this.teamData).forEach(team => {
            team.wins = 0;
            team.losses = 0;
            team.opponents = [];
        });

        const rounds = [0, 1, 2, 3, 4];
        const updatedTeams = [];
        rounds.forEach(round => {
            if (this.matches[round] == null) {
                Vue.set(this.matches, round, {});
            }
            const swissRound = this.matches[round];
            const matches = this.getMatchups();

            matches.forEach(match => {
                const [a, b] = match.teams;

                const key = `${a.wins}-${a.losses}`;
                if (swissRound[key] == null) {
                    Vue.set(swissRound, key, []);
                }

                // remove old matchups that changed
                Object.keys(swissRound).forEach(key => swissRound[key] = swissRound[key].filter(m => m.teams.includes(a) === m.teams.includes(b)));

                const matches = swissRound[key];

                const existingMatch = matches.find(m => m.teams.includes(a) && m.teams.includes(b));
                if (existingMatch == null) {
                    matches.push(match);
                } else {
                    match = existingMatch;
                }

                a.opponents.push(b);
                b.opponents.push(a);

                if (a == match.winner) {
                    a.wins++;
                    b.losses++;
                } else {
                    a.losses++;
                    b.wins++;
                }
            });
        });

        console.log('update matches', this.matches);
    }
}
